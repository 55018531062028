<template>
  <div class="controllerWaike">
    <div class="doctor iconcenter">
      <img src="@/assets/images/doctor.png" alt="" />
      <div class="info">
        <span v-html="stepInfo.doctorSay"></span>
        <!-- <span>病人准备好了吗？</span> -->
      </div>
    </div>
    <div class="controller-modules-header left">准备手术</div>
    <div class="radio-controller">
      <div
        class="item"
        v-for="(item, index) in waikeList"
        :key="index"
        :class="waike == item.type ? 'item-active' : ''"
        @click="handlerRadio('waike', item.type)"
      >
        {{ item.name }}
      </div>
    </div>
    <div class="controller-modules-header left">手术方法</div>
    <div class="radio-controller">
      <div
        class="item"
        v-for="(item, index) in shoushuLise"
        :key="index"
        :class="shoushu == item.type ? 'item-active' : ''"
        @click="handlerRadio('shoushu', item.type)"
      >
        {{ item.name }}
      </div>
    </div>

    <div class="controller-modules-header left">操作记录</div>
    <div class="controller-modules-log">
      <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import { waikeList, shoushuLise } from "@/utils/data";
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      waikeList,
      shoushuLise,
      waike: "",
      shoushu: "",
      formateSeconds,
      actionLog:[],
    };
  },
  created(){
    this.getActionLog();
  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
    }),
    handlerRadio(model, type) {
      this[model] = type || "";
      let d = {
        memberCaseId: this.memberCaseId,
        actionTime: this.getHandleTimeAt,
        component:type,
        
      };
      this.submitStep(d).then(res=>{
        this.getActionLog();
      });
    },
    async getActionLog(){
      try{
        let res = await this.$http.post(this.$interface.examActionLog,{
          componentCategory:'surgeon',
          memberCaseId:this.memberCaseId,
        })
        this.actionLog = res.data.data || [];
      }catch(err){}
    }
  },
  computed: {
    ...mapGetters(["stepInfo","getHandleTimeAt", "memberCaseId"]),
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>